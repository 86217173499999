import React, { useState } from 'react';
import {
	Box, makeStyles, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Grid,
} from "@material-ui/core";
import Button from "./Button"
import { Save } from "@material-ui/icons";
import firebase from "../firebase";

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function FirstModule({ initialState = {
	title: "",
	pictures: "",
	category: "",
	description: "",
	color: "",
	material: "",
	weight: "",
	condition: "",
	prnumber: "",
	brand: "",
	price: "",
	quantity: "",
	timeProcessing: "",
	timeDelivery: "",
	other: "",
	loading: false,
	width: "",
	height: "",
	length: "",
}, editMode = false,
	handleParentDone = () => { }
}

) {
	const useStyles = makeStyles(() => ({
		container: {
			width: 700
		},
	}));
	const classes = useStyles();

	const [
		{
			title,
			pictures,
			category,
			description,
			color,
			material,
			weight,
			condition,
			prnumber,
			brand,
			price,
			quantity,
			timeProcessing,
			timeDelivery,
			other,
			loading,
			height,
			width,
			length,
		},
		setState
	] = useState(initialState);

	return (
		<Box className={classes.container}>
			<form noValidate autoComplete="off" onSubmit={
				async (e) => {
					e.preventDefault();
					setState((prevState) => ({ ...prevState, loading: true }));
					const date = new Date();
					const result: any = {
						title,
						pictures,
						category,
						description,
						color,
						material,
						weight,
						condition,
						prnumber,
						brand,
						price,
						quantity,
						timeProcessing,
						timeDelivery,
						other,
						date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
						width,
						height,
						length,
					};

					if (Object.keys(result).filter(el => ["title", "pictures", "category", "description"].includes(el)).find((key) => result[key].length === 0)) {
						alert("Моля, попълнете всички задължителни полета!");
					} else {
						result.pictures = result.pictures.replace("\n", ",");
						try {
							if (editMode) {
								await firebase.updateProduct(result);
							} else {
								await firebase.addProduct(result);
							}
						} catch (err) {
							console.log(err)
						}
						setState({ ...initialState });
						alert("Информацията е запазена успешно!");
					}
					setState((prevState) => ({ ...prevState, loading: false }));
					handleParentDone();
				}
			}>
				<Box mb={2} mt={2}>
					<Typography variant="h5">
						{editMode ? "Редактиране " : "Добавяне "}
					на информация за продукт</Typography>
				</Box>
				<Box mb={3}>
					<TextField
						label="Заглавие на продукта"
						fullWidth
						value={title}
						required
						onChange={e => {
							const value = e.target.value;
							if (value.length < 79) {
								setState(prevState => ({ ...prevState, title: value }));
							}
						}}
					/>
				</Box>
				<Box mb={2}>
					<TextField
						label="Линкове към снимки на продукта"
						required
						multiline
						rows={7}
						value={pictures}
						onChange={e => {
							const value = e.target.value;
							if (value.split("\n").length < 8) {
								setState((prevState) => ({ ...prevState, pictures: value }));
							}
						}}
						fullWidth
						helperText={"Добавете до 7 линка, всеки на нов ред."}
						variant="filled"
					/>
				</Box>
				<Box mb={4}>
					<FormControl fullWidth>
						<InputLabel>Продуктова категория *</InputLabel>
						<Select
							value={category}
							onChange={(e) => {
								const value = e.target.value as string;
								setState((prevState) => ({ ...prevState, category: value }));
							}}
							fullWidth
							required
						>
							<MenuItem value={"1"}>Ten</MenuItem>
							<MenuItem value={"2"}>Twenty</MenuItem>
							<MenuItem value={"3"}>Thirty</MenuItem>
						</Select>
					</FormControl>
				</Box>
				<TextField
					label="Описание"
					multiline
					rows={7}
					value={description}
					onChange={e => {
						const value = e.target.value;
						if (value.length < 2000) {
							setState((prevState) => ({ ...prevState, description: value }));
						}
					}}
					fullWidth
					helperText={"*до 2000 символа"}
					variant="outlined"
					required
				/>
				<Box mb={1} mt={3}>
					<Typography variant="h6">Продуктова характеристика</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<FormControl fullWidth>
							<InputLabel>Цвят</InputLabel>
							<Select
								value={color}
								onChange={(e) => {
									const value = e.target.value as string;
									setState((prevState) => ({ ...prevState, color: value }));
								}}
								fullWidth
							>
								<MenuItem value={"Бял"}>Бял</MenuItem>
								<MenuItem value={"Зелен"}>Зелен</MenuItem>
								<MenuItem value={"Червен"}>Червен</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							label="Материал"
							fullWidth
							value={material}
							onChange={e => {
								const value = e.target.value;
								setState((prevState) => ({ ...prevState, material: value }));
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							label="Тегло (кг)"
							fullWidth
							value={weight}
							type="number"
							onChange={e => {
								const value = e.target.value;
								setState((prevState) => ({ ...prevState, weight: value }));
							}}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<FormControl fullWidth>
							<InputLabel>Състояние</InputLabel>
							<Select
								value={condition}
								onChange={(e) => {
									const value = e.target.value as string;
									setState((prevState) => ({ ...prevState, condition: value }));
								}}
								fullWidth
							>
								<MenuItem value={"Нов"}>Нов</MenuItem>
								<MenuItem value={"Употребяван"}>Употребяван</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={3}>
						<TextField
							label="Продуктов номер"
							fullWidth
							value={prnumber}
							onChange={e => {
								const value = e.target.value as string;
								setState((prevState) => ({ ...prevState, prnumber: value }));
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						<TextField
							label="Марка"
							fullWidth
							value={brand}
							onChange={e => {
								const value = e.target.value;
								setState((prevState) => ({ ...prevState, brand: value }));
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						<TextField
							label="Продажна цена (€)"
							fullWidth
							value={price}
							onChange={e => {
								const value = e.target.value;
								setState((prevState) => ({ ...prevState, price: value }));
							}}
							type="number"
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField
							label="Наличности (брой)"
							fullWidth
							value={quantity}
							onChange={e => {
								const value = e.target.value as string;
								setState((prevState) => ({ ...prevState, quantity: value }));
							}}
							type="number"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Време за обработка на поръчка (дни)"
							fullWidth
							value={timeProcessing}
							onChange={e => {
								const value = e.target.value as string;
								setState((prevState) => ({ ...prevState, timeProcessing: value }));
							}}
							type="number"
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField
							label="Време за доставка на продукта (дни)"
							fullWidth
							value={timeDelivery}
							onChange={e => {
								const value = e.target.value as string;
								setState((prevState) => ({ ...prevState, timeDelivery: value }));
							}}
							type="number"
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Други"
							fullWidth
							value={other}
							onChange={e => {
								const value = e.target.value as string;
								setState((prevState) => ({ ...prevState, other: value }));
							}}
						/>
					</Grid>
				</Grid>
				<Box mb={1} mt={3}>
					<Typography>Размери</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<TextField
							label="Дължина (см)"
							fullWidth
							value={length}
							type="number"
							onChange={e => {
								const value = e.target.value;
								setState((prevState) => ({ ...prevState, length: value }));
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							label="Височина (см)"
							fullWidth
							value={height}
							type="number"
							onChange={e => {
								const value = e.target.value;
								setState((prevState) => ({ ...prevState, height: value }));
							}}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							label="Ширина (см)"
							fullWidth
							value={width}
							type="number"
							onChange={e => {
								const value = e.target.value;
								setState((prevState) => ({ ...prevState, width: value }));
							}}
						/>
					</Grid>
				</Grid>
				<Box mb={5} mt={4} textAlign="right">
					<Button
						variant="contained"
						color="primary"
						startIcon={<Save />}
						type="submit"
						loading={loading}
						disabled={loading}
					>Запази</Button>
				</Box>
			</form>
		</Box >
	);
}
