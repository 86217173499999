import React, { useEffect, useState } from 'react';
import { createStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ButtonBase, withStyles, WithStyles, Box, Button, Grid } from '@material-ui/core';
import firebase from '../firebase';
import Theme from "../../theme";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Save, Print } from "@material-ui/icons";
import exportToCsv from "../../common/csv";
import demoData from "./../shared/demo";

const demoMode = false;

export interface Product {
	title: string;
	pictures: string;
	category: string;
	description: string;
	color: string;
	material: string;
	weight: string;
	condition: string;
	prnumber: string;
	brand: string;
	price: string;
	quantity: string;
	timeProcessing: string;
	timeDelivery: string;
	other: string;
	height: string,
	width: string,
	length: string,
	date: string,
}

export interface Column {
	id: keyof Product;
	label: string;
	minWidth?: number;
	align?: 'right';
	format?: (value: string) => React.ReactNode;
	sortable?: boolean;
}


const styles = ({ palette }: typeof Theme) => {
	return createStyles({
		root: {
			width: '100%',
		},
		container: {

		},
		sortBtn: {
			fontWeight: "bold",
			fontSize: "inherit"
		},
		img: {
			width: 45,
			marginRight: 5,
		},
		headerCell: {
			padding: 0,
		},
		headerCellContent: {
			padding: 16
		},
		filter: {
			display: "flex",
			flexDirection: "column",
			marginLeft: 10,
			"&:before": {
				content: "' '",
				width: 0,
				height: 0,
				borderLeft: "5px solid transparent",
				borderRight: "5px solid transparent",
				borderBottom: `5px solid ${palette.text.primary}`,
				marginBottom: 2
			},
			"&:after": {
				content: "' '",
				width: 0,
				height: 0,
				borderLeft: "5px solid transparent",
				borderRight: "5px solid transparent",
				borderTop: `5px solid ${palette.text.primary}`,
			}
		},
		asc: {
			"&:before": {
				borderBottom: `5px solid ${palette.primary.main}`,
			}
		},
		desc: {
			"&:after": {
				borderTop: `5px solid ${palette.primary.main}`,
			}
		}
	});
};

function sorter(arr: any[], asc: any, desc: any) {
	if (asc.length > 0) {
		return arr.sort(function (a, b) {
			var x = a[asc]; var y = b[asc];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	}
	if (desc.length > 0) {
		return arr.sort(function (a, b) {
			var x = a[desc]; var y = b[desc];
			return ((x < y) ? 1 : ((x > y) ? -1 : 0));
		});
	}
	return arr;
}

function StickyHeadTable(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [asc, setAsc] = useState("");
	const [desc, setDesc] = useState("");
	const [rows, setRows] = useState([]);


	const columns: Column[] = [
		{ id: "title", label: "Заглавие", sortable: true },
		{
			id: "pictures", label: "Снимка", format: (val) => <>
				{
					<img src={val.split(",")[0]} className={classes.img} />
				}
			</>
		},
		{ id: "category", label: "Категория", sortable: true },
		{ id: "color", label: "Цвят", sortable: true },
		{ id: "material", label: "Материал", sortable: true },
		{ id: "condition", label: "Състояние", sortable: true },
		{ id: "prnumber", label: "Пр. Номер", sortable: true },
		{ id: "brand", label: "Марка", sortable: true },
		{ id: "price", label: "Цена", format: (val) => val ? `${val} €` : "", sortable: true },
		{ id: "quantity", label: "Наличност", sortable: true },
		{ id: "timeProcessing", label: "Време за обработка", format: (val) => val ? `${val} дни` : "", sortable: true },
		{ id: "timeDelivery", label: "Време за доставка", format: (val) => val ? `${val} дни` : "", sortable: true },
		{ id: "date", label: "Дата на въвеждане" },
	];

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		if (demoMode) {
			setRows(demoData as any);
		} else {
			(firebase.getProducts() as Promise<any>).then(function (querySnapshot) {
				const results: any = [];
				querySnapshot.forEach(function (doc: any) {
					results.push(doc.data());
				});
				setRows(results);
				return false;
			})
				.catch(function (error) {
					console.log(error);
					return false;
				});
		}
	});

	return (
		<>
			<Paper className={classes.root}>
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table" id="table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
										className={classes.headerCell}
									>
										{column.sortable ?
											<ButtonBase
												className={`${classes.sortBtn} ${classes.headerCellContent}`}
												onClick={() => {
													if (asc !== column.id) {
														setDesc("");
														setAsc(column.id);
													} else {
														setAsc("");
														setDesc(column.id);
													}
												}}
											>
												{column.label}
												<span className={`${classes.filter} ${asc == column.id && classes.asc}  ${desc == column.id && classes.desc}`}></span>
											</ButtonBase>
											: <span className={classes.headerCellContent}>{column.label}</span>
										}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody  >
							{sorter(rows, asc, desc).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.title}>
										{columns.map((column: any) => {
											const value = row[column.id];
											return (
												<TableCell key={column.id} align={column.align}>
													{column.format ? column.format(value) : value}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>

			</Paper>
			<Box mb={5} mt={4} >
				<Grid container justify="flex-end">
					<Box marginRight={2}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<Save />}
							type="submit"
							onClick={() => {
								exportToCsv("test.csv", [
									columns.map(c => c.label),
									...rows.map(r => columns.map(c => {
										if (c.id === "pictures") {
											return (r[c.id] as string).split(",")[0]
										}
										return r[c.id];
									}))
								])
							}}
						>Запази като CSV</Button>
					</Box>
					<Button
						variant="contained"
						color="primary"
						startIcon={<Print />}
						type="submit" onClick={() => {
							let content = "<tr>";
							columns.forEach(c => content = content + '<th>' + c.label + '</th>');
							content = content + "</tr>";
							rows.forEach(r => {
								content = content + "<tr>";
								columns.forEach(c => {
									const label = c.id === "pictures" ? `<img style="width: 45px;" src="${(r[c.id] as string).split(",")[0]}" />` : r[c.id];
									content = content + '<td>' + label + '</td>'
								});
								content = content + "</tr>";
							})
							var frame1 = document.createElement('iframe');
							frame1.name = "frame1";
							frame1.style.position = "absolute";
							frame1.style.top = "-1000000px";
							document.body.appendChild(frame1);
							var frameDoc = (frame1.contentWindow) ? frame1.contentWindow : ((frame1.contentDocument as any).document) ? (frame1.contentDocument as any).document : frame1.contentDocument;
							frameDoc.document.open();
							frameDoc.document.write(`
							<!DOCTYPE html>
							<html>
							<head>
							<style>
							@page { size: landscape; }
							table {
							font-family: arial, sans-serif;
							border-collapse: collapse;
							width: 100%;
							}
							td, th {
							border: 1px solid #dddddd;
							text-align: left;
							padding: 8px;
							}
							tr:nth-child(even) {
							background-color: #dddddd;
							}
							</style>
							</head>
							<body> 
							<table>
							${content}
							</table>
							</body>
							</html>
							`);
							frameDoc.document.close();
							setTimeout(function () {
								(window.frames as any)["frame1"].focus();
								(window.frames as any)["frame1"].print();
								document.body.removeChild(frame1);
							}, 500);
						}}
					>Принтирай</Button>
				</Grid>
			</Box>
		</>
	);
}



export default withRouter(withStyles(styles)(StickyHeadTable));
