import React, { useState, useEffect } from "react";
import "./styles.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, CircularProgress } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../Login";
import Register from "../Register";
import EmailVerification from "../EmailVerification";
import Dashboard from "../Dashboard";
import ResetPassword from "../ResetPassword";
import firebase from "../firebase";
import outerTheme from "../../theme";

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function App() {
	const [firebaseInitialized, setFirebaseInitialized] = useState(false);

	useEffect(() => {
		firebase.isInitialized().then((val: any) => {
			setFirebaseInitialized(val);
		});
	});


	return firebaseInitialized !== false ? (
		<MuiThemeProvider theme={outerTheme}>
			<CssBaseline />
			<Router basename={process.env.PUBLIC_URL}>
				<Switch>
					<Route exact path="/" component={EmailVerification} />
					<Route exact path="/login" component={Login} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/reset-password" component={ResetPassword} />
					<Route exact path="/dashboard" component={Dashboard} />
					<Route exact path="/dashboard/overview" component={Dashboard} />
					<Route exact path="/dashboard/editor" component={Dashboard} />
					<Route exact path="/dashboard/export" component={Dashboard} />
					<Route exact path="/sales-research" component={Dashboard} />
					<Route exact path="/database" component={Dashboard} />
				</Switch>
			</Router>
		</MuiThemeProvider>
	) : <div id="loader"><CircularProgress /></div>;
}
