import React, { useEffect } from "react";
import {
	Typography, Avatar, Button, createStyles, Box,
} from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import firebase from "../firebase";
import IntroContainer from "../shared/IntroContainer";

const styles = () => {
	return createStyles({
		avatar: {
			margin: "0 auto 10px auto",
			fontSize: 40,
			width: 60,
			height: 60,
		},

	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function Dashboard(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;

	const currentUser = firebase.getCurrentUser();
	if (!firebase.getCurrentUser()) {
		props.history.replace("/login");
	}

	if (firebase.getCurrentUser()?.emailVerified) {
		props.history.replace("/dashboard");
	}

	async function logout() {
		await firebase.logout();
		props.history.push("/");
	}

	useEffect(() => {
		const interval = setInterval(() => {
			// eslint-disable-next-line no-unused-expressions
			firebase.getCurrentUser()
				?.reload()
				.then(() => {
					if (firebase.getCurrentUser()?.emailVerified) {
						clearInterval(interval);
						props.history.replace("/dashboard");
					}
				});
		}, 1000);
	}, [props.history]);

	return (
		<>
			{
				currentUser && (
					<IntroContainer>
						<Avatar className={classes.avatar}>
							<Lock fontSize="inherit" />
						</Avatar>
						<Typography component="h1" variant="h5">
							Hello
							{" "}
							{currentUser?.displayName}
							,
						</Typography>
						<Typography component="h1" variant="body1">
							Please follow the link we've sent to
							{" "}
							{currentUser?.email}
							{" "}
							in order to verify your account.
						</Typography>
						<Box marginTop="20px">
							<Button
								type="submit"
								fullWidth
								variant="outlined"
								color="secondary"
								onClick={logout}
								className={classes.submit}
								size="large"
								disableElevation
							>
								Logout

							</Button>
						</Box>
					</IntroContainer>
				)
			}
		</>
	);
}

export default withRouter(withStyles(styles)(Dashboard));
