import React, { useState } from "react";
import {
	Typography, createStyles, FormControl, TextField, Box,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import firebase from "../firebase";
import IntroContainer from "../shared/IntroContainer";
import Button from "../shared/Button";


const styles = (theme: Theme) => {
	return createStyles({
		form: {
			width: "100%", // Fix IE 11 issue.
			marginTop: 1,
		},
		link: {
			color: theme.palette.primary.main,
		},
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function ResetPassword(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;

	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	function validateEmail(vEmail: string) {
		const re = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
		return re.test(String(vEmail).toLowerCase());
	}
	async function onReset() {
		if (email.length > 0 && validateEmail(email)) {
			setLoading(true);
			await firebase.resetPassword(email).then(() => {
				// eslint-disable-next-line no-alert
				alert("An email with a password reset link has been sent. Please check your inbox.");
				props.history.replace("/");
				setLoading(false);
			}).catch(() => {
				// eslint-disable-next-line no-alert
				alert("We couldn't find an account with this email address!");
				setLoading(false);
			});
		} else {
			// eslint-disable-next-line no-alert
			alert("Please enter a valid email address and try again!");
		}
	}
	return (
		<IntroContainer>
			<Box marginBottom="10px"><Typography component="h1" variant="h5">Възстановяване на парола</Typography></Box>
			<form
				className={classes.form}
				onSubmit={(e) => {
					e.preventDefault();
					return false;
				}}
			>
				<FormControl margin="normal" required fullWidth>
					<TextField variant="outlined" label="Имейл адрес" id="email" name="email" autoComplete="off" autoFocus value={email} onChange={(e) => setEmail(e.target.value)} />
				</FormControl>
				<Box marginTop="20px">
					<Button
						type="submit"
						fullWidth
						color="primary"
						onClick={onReset}
						variant="contained"
						size="large"
						disableElevation
						loading={loading}
					>
						Възстанови
					</Button>
				</Box>
				<Box marginTop="20px">
					<Typography>
						{" "}
						<Link to="/login" className={classes.link}>Обратно към Вход</Link>
					</Typography>
				</Box>
			</form>
		</IntroContainer>
	);
}

export default withRouter(withStyles(styles)(ResetPassword));
