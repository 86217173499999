import app from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";

const config = {
	apiKey: "AIzaSyCMfuT01CCrZ2jyu_Ng06uS3rGdG36YC-4",
	authDomain: "ebay-99786.firebaseapp.com",
	projectId: "ebay-99786",
	storageBucket: "ebay-99786.appspot.com",
	messagingSenderId: "516706074096",
	appId: "1:516706074096:web:ed5b7f3e6cbc89c4c1b135"
};

class Firebase {
	auth: app.auth.Auth;

	db: app.firestore.Firestore;

	constructor() {
		app.initializeApp(config);
		this.auth = app.auth();
		this.db = app.firestore();
	}

	login(email: string, password: string) {
		return this.auth.signInWithEmailAndPassword(email, password);
	}

	logout() {
		return this.auth.signOut();
	}

	async register(name: string, email: string, password: string) {
		await this.auth.createUserWithEmailAndPassword(email, password);
		// eslint-disable-next-line no-unused-expressions
		this.auth.currentUser?.sendEmailVerification();
		return this?.auth?.currentUser?.updateProfile({
			displayName: name,
		});
	}

	async resetPassword(email: string) {
		return this.auth.sendPasswordResetEmail(email);
	}

	addQuote(quote: string) {
		if (!this.auth.currentUser) {
			// eslint-disable-next-line no-alert
			return alert("Not authorized");
		}

		return this.db.doc(`users/${this.auth.currentUser.uid}`).set({
			quote,
		});
	}

	addProduct(product: any) {
		if (!this.auth.currentUser) {
			// eslint-disable-next-line no-alert
			return alert("Not authorized");
		}
		return this.db.collection(this.auth.currentUser.uid).doc(product.title.replace(" ", "_")).set(product);
	}

	updateProduct(product: any) {
		if (!this.auth.currentUser) {
			// eslint-disable-next-line no-alert
			return alert("Not authorized");
		}
		return this.db.collection(this.auth.currentUser.uid).doc(product.title.replace(" ", "_")).update(product);
	}

	getProducts() {
		if (!this.auth.currentUser) {
			// eslint-disable-next-line no-alert
			return alert("Not authorized");
		}
		return this.db.collection(this.auth.currentUser.uid).get();

	}

	isInitialized() {
		return new Promise((resolve) => {
			this.auth.onAuthStateChanged(resolve);
		});
	}

	getCurrentUser() {
		return this.auth.currentUser && this.auth.currentUser;
	}

	isUserVerified() {
		return this.auth.currentUser && this.auth.currentUser.emailVerified;
	}

	async getCurrentUserQuote() {
		const quote = await this.db.doc(`users/${this?.auth?.currentUser?.uid}`).get();
		return quote.get("quote");
	}
}

export default new Firebase();
