import { createMuiTheme } from "@material-ui/core/styles";

const outerTheme = createMuiTheme({
	palette: {
		// type: "dark"
		primary: {
			main: "#3080aa",
		},
		// secondary: {
		// 	main: "#535a5e",
		// },
	},
	overrides: {
		// // eslint-disable-next-line @typescript-eslint/naming-convention
		// MuiButton: {
		// 	sizeLarge: {
		// 		borderRadius: "20px",
		// 	},
		// 	text: {
		// 		textTransform: "none",
		// 		textDecoration: "underline",
		// 		fontSize: "16px",
		// 		fontWeight: 400,
		// 		color: "#238fbd",
		// 	},
		// },
	},
});

export default outerTheme;
