import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { createStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ButtonBase, withStyles, WithStyles, Checkbox, Box } from '@material-ui/core';
import firebase from '../firebase';
import Theme from "../../theme";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import demoData from "./../shared/demo";
import { Column } from "./../Overview/Overview";
const demoMode = false;

const styles = ({ palette, shape }: typeof Theme) => {
	return createStyles({
		root: {
			width: '100%',
		},
		container: {

		},
		sortBtn: {
			fontWeight: "bold",
			fontSize: "inherit"
		},
		img: {
			width: 45,
			marginRight: 5,
		},
		headerCell: {
			padding: 0,
		},
		headerCellContent: {
			padding: 16
		},
		filter: {
			display: "flex",
			flexDirection: "column",
			marginLeft: 10,
			"&:before": {
				content: "' '",
				width: 0,
				height: 0,
				borderLeft: "5px solid transparent",
				borderRight: "5px solid transparent",
				borderBottom: `5px solid ${palette.text.primary}`,
				marginBottom: 2
			},
			"&:after": {
				content: "' '",
				width: 0,
				height: 0,
				borderLeft: "5px solid transparent",
				borderRight: "5px solid transparent",
				borderTop: `5px solid ${palette.text.primary}`,
			}
		},
		asc: {
			"&:before": {
				borderBottom: `5px solid ${palette.primary.main}`,
			}
		},
		desc: {
			"&:after": {
				borderTop: `5px solid ${palette.primary.main}`,
			}
		},
		btn: {
			color: palette.primary.main,
			padding: "10px 16px",
			border: `1px solid ${palette.primary.main}`,
			borderRadius: shape.borderRadius,
			textDecoration: "none",
			fontSize: 16,
			display: "inline-block",
			marginTop: 30,
			marginBottom: 30,
		},
		disabled: {
			pointerEvents: "none",
			opacity: ".3"
		}
	});
};

function sorter(arr: any[], asc: any, desc: any) {
	if (asc.length > 0) {
		return arr.sort(function (a, b) {
			var x = a[asc]; var y = b[asc];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	}
	if (desc.length > 0) {
		return arr.sort(function (a, b) {
			var x = a[desc]; var y = b[desc];
			return ((x < y) ? 1 : ((x > y) ? -1 : 0));
		});
	}
	return arr;
}

function Export(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [asc, setAsc] = useState("");
	const [desc, setDesc] = useState("");
	const [rows, setRows] = useState([]);
	const [checkedIds, setCheckedIds] = useState("");

	const columns: Column[] = [
		{ id: "title", label: "Заглавие", sortable: true },
		{
			id: "pictures", label: "Снимки", format: (val) => <>
				{
					val.split(",").map((el) => <img src={el} className={classes.img} />)
				}
			</>
		},
		{ id: "category", label: "Категория", sortable: true },
		{ id: "description", label: "Описание", sortable: true },
		{ id: "color", label: "Цвят", sortable: true },
		{ id: "material", label: "Материал", sortable: true },
		{ id: "height", label: "Височина", format: (val) => val ? `${val} см` : "", sortable: true },
		{ id: "width", label: "Ширина", format: (val) => val ? `${val} см` : "", sortable: true },
		{ id: "length", label: "Дължина", format: (val) => val ? `${val} см` : "", sortable: true },
		{ id: "weight", label: "Тегло", format: (val) => val ? `${val} кг` : "", sortable: true },
		{ id: "condition", label: "Състояние", sortable: true },
		{ id: "prnumber", label: "Пр. Номер", sortable: true },
		{ id: "brand", label: "Марка", sortable: true },
		{ id: "price", label: "Цена", format: (val) => val ? `${val} €` : "", sortable: true },
		{ id: "quantity", label: "Наличност", sortable: true },
		{ id: "timeProcessing", label: "Време за обработка", format: (val) => val ? `${val} дни` : "", sortable: true },
		{ id: "timeDelivery", label: "Време за доставка", format: (val) => val ? `${val} дни` : "", sortable: true },
		{ id: "other", label: "Други", },
	];

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const toggleCheckbox = (title: string) => {
		if (checkedIds.includes(title)) {
			setCheckedIds(checkedIds.replace("," + title, ""));
		} else {
			setCheckedIds(checkedIds + "," + title);
		}
	}

	useEffect(() => {
		if (demoMode) {
			setRows(demoData as any);
		} else {
			(firebase.getProducts() as Promise<any>).then(function (querySnapshot) {
				const results: any = [];
				querySnapshot.forEach(function (doc: any) {
					results.push(doc.data());
				});
				setRows(results);
				console.log(results);
				return false;
			})
				.catch(function (error) {
					console.log(error);
					return false;
				});
		}
	});

	const csvData = rows.filter((row: any) => checkedIds.includes(row.title));

	return (
		<>
			<Paper className={classes.root}>
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
										className={classes.headerCell}
									>
										{column.sortable ?
											<ButtonBase
												className={`${classes.sortBtn} ${classes.headerCellContent}`}
												onClick={() => {
													if (asc !== column.id) {
														setDesc("");
														setAsc(column.id);
													} else {
														setAsc("");
														setDesc(column.id);
													}
												}}
											>
												{column.label}
												<span className={`${classes.filter} ${asc == column.id && classes.asc}  ${desc == column.id && classes.desc}`}></span>
											</ButtonBase>
											: <span className={classes.headerCellContent}>{column.label}</span>
										}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{sorter(rows, asc, desc).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.title}>
										<TableCell>
											<Checkbox
												checked={checkedIds.includes(row.title)}
												onChange={() => toggleCheckbox(row.title)}
												inputProps={{ 'aria-label': 'primary checkbox' }}
												color="primary"
											/>
										</TableCell>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<TableCell key={column.id} align={column.align}>
													{column.format ? column.format(value) : value}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
			<Box textAlign="right" className={`${!checkedIds.length && classes.disabled}`}>
				<CSVLink className={classes.btn} data={csvData} filename="ebay-products.csv">Изтегли CSV</CSVLink>
			</Box>
		</>
	);
}

export default withRouter(withStyles(styles)(Export));
