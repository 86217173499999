import React, { useEffect, useState } from "react";
import {
	makeStyles, List, ListItem, ListItemIcon, ListItemText, Collapse,
} from "@material-ui/core";
import { Add, Inbox, ExpandLess, ExpandMore, Create, Visibility, ExitToApp } from "@material-ui/icons";
import { useLocation, useHistory } from "react-router-dom";


interface Route {
	route: string;
	label: string;
	icon: React.ReactNode;
	children?: Route[];
}


const routes: Route[] = [
	{
		label: "Въвеждане",
		route: "/dashboard",
		icon: <Add />,
	},
	{
		label: "База данни",
		icon: <Visibility />,
		route: "/dashboard/overview",
	},
	{
		label: "Корекция",
		icon: <Create />,
		route: "/dashboard/editor",
	},
	{
		label: "Експорт към eBay",
		icon: <ExitToApp />,
		route: "/dashboard/export",
	}
];




// eslint-disable-next-line @typescript-eslint/naming-convention
export default function SideMenu() {
	const location = useLocation();
	const history = useHistory();


	const useStyles = makeStyles(() => ({
		icon: {
			minWidth: 30,
		},
		text: {
			whiteSpace: "nowrap",
		},
		root: {
			"&$disabled": {
				opacity: 1,
			},
		},
		disabled: {

		},
		child: {
			position: "relative",
			left: "6px",
			top: "-6px",
			fontSize: "20px",
		},
		nested: {
			paddingLeft: 25,
		},
	}));

	const classes = useStyles();

	// if (firebase.getCurrentUser()?.email === "martin.da4ev@gmail.com" || firebase.getCurrentUser()?.email === "nexosoftware@gmail.com") {
	// 	routes.push(
	// 		{
	// 			label: "База Данни",
	// 			route: "/database",
	// 			icon: <Storage />,
	// 		},
	// 	)
	// }

	const [open, setOpen] = useState("");

	const handleClick = (r: Route) => {
		const { route } = r;
		if (route === open) {
			setOpen("");
		} else {
			setOpen(r?.route || "");
		}
	}

	useEffect(() => {
		for (const route of routes) {
			if (route.children !== undefined) {
				const find = route.children.find((r) => `${r.route}` === location.pathname);
				setOpen(find ? route.route : "");
				break;
			}

			if (route.route === location.pathname) {
				setOpen(route.route);
				break;
			}
		}
	}, []);

	return (
		<List>
			{
				routes.map((r) => {
					if (r.children) {
						return (
							<>
								<ListItem key={r.route} button onClick={() => handleClick(r)} >
									<ListItemIcon>
										<Inbox />
									</ListItemIcon>
									<ListItemText primary={r.label} classes={{ root: classes.text }} />
									{open === r.route ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={open === r.route} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{
											r.children.map((rC) => {
												const link = `${rC.route}`;
												return (
													<ListItem button key={rC.route} className={classes.nested} selected={location.pathname === link} classes={{ root: classes.root, disabled: classes.disabled }} disabled={!rC.route} onClick={() => (rC.route ? (history.push(link)) : false)}>
														<ListItemIcon classes={{ root: classes.icon }}>{rC.icon}</ListItemIcon>
														<ListItemText primary={rC.label} />
													</ListItem>
												)
											})
										}
									</List>
								</Collapse>
							</>
						)
					}

					return (
						<ListItem button key={r.route} selected={location.pathname === r.route} onClick={() => (r.route ? (history.push(r.route)) : false)} disabled={r.route === undefined} classes={{ root: classes.root, disabled: classes.disabled }}>
							<ListItemIcon classes={{ root: classes.icon }}>{r.icon}</ListItemIcon>
							<ListItemText primary={r.label} classes={{ root: classes.text }} />
						</ListItem>
					)
				})
			}
		</List >
	)
}	
