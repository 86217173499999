import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import {
	Box, Theme, makeStyles, CircularProgress,
} from "@material-ui/core";


interface ButtonPropsExtended extends ButtonProps {
	loading?: boolean;
}


// eslint-disable-next-line @typescript-eslint/naming-convention
export default function CustomButton(props: ButtonPropsExtended) {
	const { loading, ...nativeProps } = props;
	const useStyles = makeStyles((theme: Theme) => ({
		container: {
			position: "relative",
			backgroundColor: theme.palette.primary.main,
		},
		load: {
			position: "absolute",
			top: 8,
			transition: "0.3s all",
		},
		hidden: {
			opacity: 0,
			transition: "0.3s all",
		},
	}));
	const classes = useStyles();
	return (
		<Button {...nativeProps} disableElevation className={loading ? classes.container : ""} disabled={loading || props.disabled}>
			<Box className={loading ? classes.hidden : ""}>{props.children}</Box>
			{loading && (
				<Box className={classes.load}>
					{" "}
					<CircularProgress color="inherit" size={25} />
				</Box>
			)}
		</Button>
	);
}
