import React from "react";
import {
	createStyles, Box, Grid, Theme, Divider, Hidden, Typography,
} from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
	RouteComponentProps, withRouter, Switch, Route,
} from "react-router-dom";
import firebase from "../firebase";
import SideMenu from "../shared/SideMenu";
import AccountMenu from "../shared/AccountMenu";
import FirstModule from "../shared/FirstModule";
import Overview from "../Overview/Overview";
import Editor from "../Editor/Editor";
import Export from "../Export/Export";
import logo from "../../assets/logo.png";

const styles = ({ palette, breakpoints }: Theme) => {
	return createStyles({
		"@global": {
			"html, body, #root": {
				width: "100%",
				height: "100%",
			},
		},
		body: {
			height: "100%",
			backgroundColor: palette.background.default,
			[breakpoints.down("sm")]: {
				display: "block",
			},
			[breakpoints.up("sm")]: {
				"> *": {
					minHeight: "100%",
				},
			},
		},
		paper: {
			backgroundColor: palette.background.paper,
			padding: "1px",
			minWidth: 260,
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between"
		},
		info: {
			backgroundColor: palette.background.default,
		},
		content: {
			flex: 1,
			overflow: "auto",
		},

	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function Dashboard(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;

	if (!firebase.getCurrentUser()) {
		props.history.replace("/login");
		return <></>;
	}


	return (
		<Grid container className={classes.body} wrap="nowrap">
			<Box className={classes.paper}>
				<Box>
					<img src={logo} alt="logo" style={{
						height: 70,
						margin: "10px auto 20px auto",
						display: "block",
					}} />
					<AccountMenu />
					<Hidden smDown>
						<Box><Divider variant="fullWidth" color="primary" /></Box>
						<SideMenu />
					</Hidden>
				</Box>
				<Box padding={2} className={classes.info}>
					<Typography variant="body2">Produced by: MAXX IQ LTD<br />
					Serial Number: MXQ-03-NEX-V21
					</Typography>
				</Box>
			</Box>
			<Box padding="17px 20px" overflow="auto" flex={1}>
				<Switch>
					<Route exact path="/dashboard/" component={FirstModule} />
					<Route path="/dashboard/overview/" component={Overview} />
					<Route path="/dashboard/editor/" component={Editor} />
					<Route path="/dashboard/export/" component={Export} />
				</Switch>
			</Box>
		</Grid>
	);
}

export default withRouter(withStyles(styles)(Dashboard));
