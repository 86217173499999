import React, { useEffect, useState } from 'react';
import { createStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, ButtonBase, withStyles, WithStyles, IconButton, Slide, Dialog, DialogContent, Typography, Grid, TextField, Box } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Create } from '@material-ui/icons';
import firebase from '../firebase';
import Theme from "../../theme";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import demoData from "./../shared/demo";
import { Column } from "./../Overview/Overview";
import FirstModule from "./../shared/FirstModule";

const demoMode = false;
const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const styles = ({ palette }: typeof Theme) => {
	return createStyles({
		root: {
			width: '100%',
		},
		container: {

		},
		sortBtn: {
			fontWeight: "bold",
			fontSize: "inherit"
		},
		img: {
			width: 45,
			marginRight: 5,
		},
		headerCell: {
			padding: 0,
		},
		headerCellContent: {
			padding: 16
		},
		filter: {
			display: "flex",
			flexDirection: "column",
			marginLeft: 10,
			"&:before": {
				content: "' '",
				width: 0,
				height: 0,
				borderLeft: "5px solid transparent",
				borderRight: "5px solid transparent",
				borderBottom: `5px solid ${palette.text.primary}`,
				marginBottom: 2
			},
			"&:after": {
				content: "' '",
				width: 0,
				height: 0,
				borderLeft: "5px solid transparent",
				borderRight: "5px solid transparent",
				borderTop: `5px solid ${palette.text.primary}`,
			}
		},
		asc: {
			"&:before": {
				borderBottom: `5px solid ${palette.primary.main}`,
			}
		},
		desc: {
			"&:after": {
				borderTop: `5px solid ${palette.primary.main}`,
			}
		}
	});
};

function sorter(arr: any[], asc: any, desc: any) {
	if (asc.length > 0) {
		return arr.sort(function (a, b) {
			var x = a[asc]; var y = b[asc];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	}
	if (desc.length > 0) {
		return arr.sort(function (a, b) {
			var x = a[desc]; var y = b[desc];
			return ((x < y) ? 1 : ((x > y) ? -1 : 0));
		});
	}
	return arr;
}

function Editor(props: RouteComponentProps & WithStyles): JSX.Element {
	const { classes } = props;
	const columns: Column[] = [
		{ id: "title", label: "Заглавие", sortable: true },
		{
			id: "pictures", label: "Снимка", format: (val) => <>
				{
					<img src={val.split(",")[0]} className={classes.img} />
				}
			</>
		},
		{ id: "category", label: "Категория", sortable: true },
		{ id: "color", label: "Цвят", sortable: true },
		{ id: "material", label: "Материал", sortable: true },
		{ id: "condition", label: "Състояние", sortable: true },
		{ id: "prnumber", label: "Пр. Номер", sortable: true },
		{ id: "brand", label: "Марка", sortable: true },
		{ id: "price", label: "Цена", format: (val) => val ? `${val} €` : "", sortable: true },
		{ id: "quantity", label: "Наличност", sortable: true },
		{ id: "timeProcessing", label: "Време за обработка", format: (val) => val ? `${val} дни` : "", sortable: true },
		{ id: "timeDelivery", label: "Време за доставка", format: (val) => val ? `${val} дни` : "", sortable: true },
		{ id: "date", label: "Дата на въвеждане" },
	];
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [asc, setAsc] = useState("");
	const [desc, setDesc] = useState("");
	const [rows, setRows] = useState([] as any);
	const [editTitle, setEditTitle] = useState("");
	const [open, setOpen] = React.useState(false);
	const [title, setTitle] = React.useState("");
	const [category, setCategory] = React.useState("");
	const [prNumber, setPrNumber] = React.useState("");
	const [brand, setBrand] = React.useState("");

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		if (demoMode) {
			setRows(demoData as any);
		} else {
			(firebase.getProducts() as Promise<any>).then(function (querySnapshot) {
				const results: any = [];
				querySnapshot.forEach(function (doc: any) {
					results.push(doc.data());
				});
				setRows(results);
				console.log(results);
				return false;
			})
				.catch(function (error) {
					console.log(error);
					return false;
				});
		}
	});

	const handleParentDone = () => {
		setOpen(false);
	}

	return (
		<>
			<Typography>Търси по</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<Box marginTop={1} marginBottom={2}>
						<TextField
							label="Заглавие"
							fullWidth
							value={title}
							onChange={e => {
								const value = e.target.value;
								setTitle(value)
							}}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={3}>
					<Box marginTop={1} marginBottom={2}>
						<TextField
							label="Категория"
							fullWidth
							value={category}
							onChange={e => {
								const value = e.target.value;
								setCategory(value)
							}}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={3}>
					<Box marginTop={1} marginBottom={2}>
						<TextField
							label="Продуктов Номер"
							fullWidth
							value={prNumber}
							onChange={e => {
								const value = e.target.value;
								setPrNumber(value)
							}}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={3}>
					<Box marginTop={1} marginBottom={2}>
						<TextField
							label="Марка"
							fullWidth
							value={brand}
							onChange={e => {
								const value = e.target.value;
								setBrand(value)
							}}
						/>
					</Box>
				</Grid>
			</Grid>
			<Paper className={classes.root}>
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
										className={classes.headerCell}
									>
										{column.sortable ?
											<ButtonBase
												className={`${classes.sortBtn} ${classes.headerCellContent}`}
												onClick={() => {
													if (asc !== column.id) {
														setDesc("");
														setAsc(column.id);
													} else {
														setAsc("");
														setDesc(column.id);
													}
												}}
											>
												{column.label}
												<span className={`${classes.filter} ${asc == column.id && classes.asc}  ${desc == column.id && classes.desc}`}></span>
											</ButtonBase>
											: <span className={classes.headerCellContent}>{column.label}</span>
										}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody  >
							{sorter(rows.filter((r: any) => r.title.includes(title) && r.category.includes(category) && r.prnumber.includes(prNumber) && r.brand.includes(brand)), asc, desc).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.title}>
										<TableCell   >
											<IconButton color="primary" aria-label="upload picture" component="span" onClick={
												() => {
													setEditTitle(row.title);
													console.log("click");
													console.log(editTitle);
													handleClickOpen();
												}
											}>
												<Create />
											</IconButton>
										</TableCell>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<TableCell key={column.id} align={column.align}>
													{column.format ? column.format(value) : value}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
			<Dialog
				maxWidth="lg"
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogContent>
					<FirstModule
						key={editTitle}
						editMode={true}
						initialState={rows?.find((el: any) => el.title === editTitle)}
						handleParentDone={() => handleParentDone()}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default withRouter(withStyles(styles)(Editor));
